import * as React from 'react';
import { Redirect } from 'react-router';
import { UploadPaths } from 'features/upload/models/uploadModels';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { generateColumns, formatRAGStatus } from 'common/helpers/utils';
import { PaginationView } from 'features/pagination/models/paginationModel';
import { getRagContractsSuccess } from 'features/contract/actions/contractActions';
import { ContractWithRAG } from 'features/contract/models/contractModels';
import { DownloadPaths } from 'features/download/models/downloadModel';
import { PaginationFilters } from 'features/pagination/reducers/paginationReducer';
import { SaveFilters, ClearFilters } from 'features/pagination/actions/paginationActions';
import { Antd3Icon } from 'common/components/deprecated/antd3';
import { EditPaths } from 'features/edit/models/editModel';
import { PaddedSection } from 'common/components';
import { RAGExpandableRow } from 'features/pagination/components/RAGExpandableRow';

import { DashboardTableFiltersBar } from './DashboardTableFiltersBar';
import { ContractDashboardContainerProps } from '../containers/ContractDashboardContainer';
import { DashboardPaths } from '../models/dashboardModel';
import { ActionableTableRow } from './ActionableTableRow';

interface ContractDashboardDispatchProps {
    redirect(path: string): void;
}

type Props = ContractDashboardContainerProps & ContractDashboardDispatchProps;

const contractsDashboardColumns = (
    clearFilters: (paginatedView: PaginationView) => ClearFilters,
    saveFilters: (filters: PaginationFilters) => SaveFilters,
) => [
    {
        title: 'Project',
        key: 'title',
        render: (title: string) => (
            <ActionableTableRow
                label={title}
                edit={{
                    paginatedView: PaginationView.Benefits,
                    values: { contractTitle: title },
                    path: EditPaths.Benefits,
                }}
                download={{
                    paginatedView: PaginationView.DownloadBenefits,
                    values: { contractTitle: title },
                    path: DownloadPaths.Benefits,
                }}
                handlers={{ clearFilters, saveFilters }}
            />
        ),
    },
    {
        title: 'Ref. No.',
        key: 'referenceNumber',
    },
    {
        title: 'Benefits Delivered',
        key: 'benefitDeliveredNumberRag',
        render: (benefitDeliveredNumberRag: string, { deliveredBenefitCount }: ContractWithRAG) => (
            <>
                <Antd3Icon
                    type="minus-circle"
                    theme="filled"
                    style={{ color: formatRAGStatus(benefitDeliveredNumberRag) }}
                />
                <span> {deliveredBenefitCount}</span>
            </>
        ),
    },
    {
        title: 'Points',
        key: 'benefitPointsRag',
        render: (benefitPointsRag: string, { deliveredPoints }: ContractWithRAG) => (
            <>
                <Antd3Icon
                    type="minus-circle"
                    theme="filled"
                    style={{ color: formatRAGStatus(benefitPointsRag) }}
                />
                <span> {deliveredPoints}</span>
            </>
        ),
    },
    {
        title: 'Value',
        key: 'benefitValueRag',
        render: (benefitValueRag: string, { deliveredValue }: ContractWithRAG) => (
            <>
                <Antd3Icon
                    type="minus-circle"
                    theme="filled"
                    style={{ color: formatRAGStatus(benefitValueRag) }}
                />
                <span> {deliveredValue}</span>
            </>
        ),
    },
    {
        title: 'Emissions Savings',
        key: 'benefitEmissionSavingsRag',
        render: (
            benefitEmissionSavingsRag: string,
            { deliveredEmissionSavings }: ContractWithRAG,
        ) => (
            <>
                <Antd3Icon
                    type="minus-circle"
                    theme="filled"
                    style={{ color: formatRAGStatus(benefitEmissionSavingsRag) }}
                />
                <span> {deliveredEmissionSavings}</span>
            </>
        ),
    },
];

export class ContractDashboardComponent extends React.Component<Props> {
    public render(): JSX.Element {
        const { loading, isUserTenant } = this.props;

        if (!loading && !isUserTenant) {
            return <Redirect to={UploadPaths.PendingEvidence} />;
        }

        return (
            <PaddedSection header="Projects">
                <DashboardTableFiltersBar
                    filterPageUrl={DashboardPaths.ContractsFilter}
                    filterId={PaginationView.ContractRAGDashboard}
                />
                <PaginatedTable
                    data={this.props.contracts}
                    columns={generateColumns(
                        contractsDashboardColumns(this.props.clearFilters, this.props.saveFilters),
                    )}
                    emptyText="No projects found"
                    view={PaginationView.ContractRAGDashboard}
                    paginationSuccessCallback={getRagContractsSuccess}
                    expandedRowRender={(rowData) => <RAGExpandableRow rowData={rowData} />}
                />
            </PaddedSection>
        );
    }
}
