import * as React from 'react';
import { ImportForm } from 'features/import/components/ImportForm';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';

import { ImportContractsContainerProps } from '../containers/ImportContractsContainer';
import { ErrorList } from './ErrorList';

type ImportContractsProps = ImportContractsContainerProps & Antd3FormProps;

class ImportContractsForm extends React.Component<ImportContractsProps> {
    private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.props.form.validateFields((error, { fileList: { file } }) => {
            if (!error) {
                return file && this.props.importContractsRequest(file);
            }
            return;
        });
    };

    public render(): JSX.Element {
        const { form, isProcessing, errors } = this.props;
        const importError = errors && errors.length > 0;

        return (
            <>
                <ImportForm
                    header="Import Projects"
                    form={form}
                    href="import-projects"
                    onSubmit={this.handleSubmit}
                    helpUrl="https://support.cenefits.com/articles/admins-contract-managers/how-to-import-contracts"
                    processing={isProcessing}
                    importError={importError}
                />
                {!isProcessing && importError && <ErrorList errors={errors} />}
            </>
        );
    }
}

export const ImportContracts = Antd3Form.create({})(ImportContractsForm);
