import { AppAction } from 'common/actions/appAction';
import { Role } from 'features/user/models/userModel';
import { orderBy } from 'lodash';

import * as actions from '../actions';
import {
    Benefit,
    BenefitCategory,
    ContractBenefit,
    BenefitTemplate,
    BenefitTemplateWithRag,
    BenefitPriorityDashboardItem,
    BenefitNoteData,
    FetchedBenefitPriority,
} from '../models/benefitModels';

export interface BenefitState {
    benefitCategories: BenefitCategory[];
    benefitRoles: Role[];
    benefit: Benefit;
    benefits: Benefit[];
    benefitTemplatesWithRag: BenefitTemplateWithRag[];
    priorityDashboard: BenefitPriorityDashboardItem[];
    myBenefits: Benefit[];
    contractBenefits: ContractBenefit[];
    isCreating: boolean;
    isFetching: boolean;
    isFiltered: boolean;
    contractBenefitsFiltered: boolean;
    benefitTemplate: BenefitTemplate | null;
    benefitTemplates: BenefitTemplate[];
    filteredContractId: number;
    benefitTemplatesFetching: boolean;
    isFetchingBenefitCategories: boolean;
    isUpdating: boolean;
    isFetchingRoles: boolean;
    selectedContractId: number;
    priorities: FetchedBenefitPriority[];
    isFetchingPriorities: boolean;
    selectedBenefitTemplate: BenefitTemplate[];
    benefitTemplateFilterDateRange: string[];
    benefitPrioritiesFilterDateRange: string[];
    benefitNotes: BenefitNoteData[];
    formVisible: boolean;
}

const defaultBenefit = {
    id: 0,
    benefitTemplate: 0,
    priority: '',
    organisation: 0,
    category: 0,
    contract: 0,
    description: '',
    finalDeliveryDate: '',
    outcome: '',
    points: 0,
    quantity: 0,
    startDate: '',
    status: '',
    value: 0,
    deliveryDates: [],
    emissionSavings: 0,
};

export const defaultBenefitState = {
    benefitCategories: [],
    benefitRoles: [],
    benefits: [],
    benefitTemplatesWithRag: [],
    priorityDashboard: [],
    myBenefits: [],
    contractBenefits: [],
    benefitTemplate: null,
    benefitTemplates: [],
    benefit: defaultBenefit,
    isCreating: false,
    isFetching: false,
    isFiltered: false,
    isFetchingBenefitTemplate: false,
    isFetchingBenefitCategories: false,
    contractBenefitsFiltered: false,
    benefitTemplatesFetching: false,
    filteredContractId: -1,
    isUpdating: false,
    isFetchingRoles: false,
    isFetchingPriorities: false,
    selectedContractId: -1,
    priorities: [],
    selectedBenefitTemplate: [],
    benefitTemplateFilterDateRange: [],
    benefitPrioritiesFilterDateRange: [],
    benefitNotes: [],
    formVisible: false,
};

export const benefitReducer = (state: BenefitState = defaultBenefitState, action: AppAction) => {
    switch (action.type) {
        case actions.CREATE_BENEFIT_REQUESTED:
            return {
                ...state,
                isCreating: true,
            };
        case actions.GET_BENEFITS_WITH_SUBMITTERS_REQUESTED:
        case actions.FILTER_BENEFITS_REQUESTED:
        case actions.FILTER_CONTRACT_BENEFITS_REQUESTED:
        case actions.GET_CONTRACT_BENEFITS_REQUESTED:
        case actions.GET_MY_BENEFITS_REQUESTED:
        case actions.GET_BENEFIT_TEMPLATE_DATA_REQUESTED:
        case actions.GET_CONTRACT_BENEFIT_DATA_REQUESTED:
        case actions.GET_BENEFIT_TEMPLATE_REQUESTED:
        case actions.GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_REQUESTED:
        case actions.GET_BENEFIT_NOTES_REQUESTED:
        case actions.MIGRATE_BENEFITS_REQUESTED:
            return {
                ...state,
                isFetching: true,
            };
        case actions.GET_BENEFITS_WITH_SUBMITTERS_SUCCEED:
            return {
                ...state,
                ...action.payload,
            };
        case actions.GET_BENEFIT_CATEGORIES_REQUESTED:
            return {
                ...state,
                isFetchingBenefitCategories: true,
            };
        case actions.GET_BENEFIT_CATEGORIES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetchingBenefitCategories: false,
            };
        case actions.GET_BENEFIT_CATEGORIES_FAILED:
            return {
                ...state,
                isFetchingBenefitCategories: false,
            };
        case actions.GET_BENEFIT_TEMPLATE_SUCCEED:
            return {
                ...state,
                benefitTemplates: [
                    action.payload,
                    ...state.benefitTemplates.filter(({ id }) => id !== action.payload!.id),
                ],
                isFetching: false,
            };
        case actions.GET_BENEFITS_SUCCEED:
        case actions.GET_CONTRACT_BENEFITS_SUCCEED:
        case actions.GET_MY_BENEFITS_SUCCEED:
        case actions.GET_RAG_BENEFIT_TEMPLATE_SUCCEED:
        case actions.GET_BENEFIT_PRIORITY_DASHBOARD_SUCCEED:
        case actions.GET_BENEFIT_TEMPLATE_DATA_SUCCEED:
        case actions.GET_BENEFIT_NOTES_SUCCEED:
        case actions.MIGRATE_BENEFITS_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isCreating: false,
                isFetching: false,
                isFiltered: false,
            };
        case actions.CREATE_BENEFIT_SUCCEED:
            return {
                ...state,
                isCreating: false,
                isFetching: false,
                isFiltered: false,
            };
        case actions.RESET_FILTERED_CONTRACT_ID:
            return {
                ...state,
                filteredContractId: -1,
            };
        case actions.FILTER_CONTRACT_BENEFITS_SUCCEED:
            return {
                ...state,
                ...action.payload,
                contractBenefitsFiltered: true,
            };
        case actions.FILTER_CONTRACT_BENEFITS_FAILED:
            return {
                ...state,
                contractBenefitsFiltered: false,
            };
        case actions.CREATE_BENEFIT_FAILED:
        case actions.FILTER_BENEFITS_FAILED:
        case actions.GET_CONTRACT_BENEFITS_FAILED:
        case actions.GET_MY_BENEFITS_FAILED:
        case actions.GET_BENEFIT_TEMPLATE_DATA_FAILED:
        case actions.GET_CONTRACT_BENEFIT_DATA_FAILED:
        case actions.GET_BENEFIT_TEMPLATE_FAILED:
        case actions.GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_FAILED:
        case actions.GET_BENEFIT_NOTES_FAILED:
        case actions.MIGRATE_BENEFITS_FAILED:
            return {
                ...state,
                isCreating: false,
                isFetching: false,
                isFiltered: false,
            };
        case actions.GET_BENEFITS_ROLES_REQUESTED:
            return {
                ...state,
                isFetchingRoles: true,
            };
        case actions.GET_BENEFITS_ROLES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetchingRoles: false,
            };
        case actions.GET_BENEFIT_PRIORITIES_REQUESTED:
            return {
                ...state,
                isFetchingPriorities: true,
            };
        case actions.GET_BENEFIT_PRIORITIES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetchingPriorities: false,
            };
        case actions.GET_BENEFITS_ROLES_FAILED:
            return {
                ...state,
                isFetchingRoles: false,
            };
        case actions.FILTER_BENEFITS_SUCCEED:
            return {
                ...state,
                ...action.payload,
                isFetching: false,
                isFiltered: true,
            };
        case actions.DELETE_BENEFIT_SUCCEED:
            return {
                ...state,
                contractBenefits: state.contractBenefits.filter(({ id }) => id !== action.payload),
                myBenefits: state.myBenefits.filter(({ id }) => id !== action.payload),
                benefits: state.benefits.filter(({ id }) => id !== action.payload),
            };
        case actions.GET_CONTRACT_BENEFIT_DATA_SUCCEED:
            return {
                ...state,
                benefit: action.payload,
                benefits: [
                    action.payload,
                    ...state.benefits.filter((benefit) => benefit.id !== action.payload!.id),
                ],
                isFetching: false,
            };
        case actions.ADD_BENEFIT_ROLE_SUCCEED:
            return {
                ...state,
                benefitRoles: [...state.benefitRoles, action.payload],
            };
        case actions.DELETE_BENEFIT_ROLE_SUCCEED:
            let newState = state.benefitRoles.filter((role) => role.id !== action.payload!.roleId);

            if (action.payload!.organisationId) {
                newState = newState.filter(
                    (role) => role.organisation !== action.payload!.organisationId,
                );
            }

            return {
                ...state,
                benefitRoles: newState,
            };
        case actions.ARCHIVE_BENEFIT_CATEGORY_SUCCEED:
            return {
                ...state,
                benefitCategories: state.benefitCategories.filter(
                    ({ id }) => id !== action.payload,
                ),
            };
        case actions.GET_BENEFIT_TEMPLATES_REQUESTED:
            return {
                ...state,
                benefitTemplatesFetching: true,
            };
        case actions.GET_BENEFIT_TEMPLATES_FAILED:
            return {
                ...state,
                benefitTemplatesFetching: false,
            };
        case actions.GET_BENEFIT_TEMPLATES_SUCCEED:
            return {
                ...state,
                ...action.payload,
                benefitTemplatesFetching: false,
            };
        case actions.CREATE_BENEFIT_TEMPLATE_SUCCEED:
        case actions.CLONE_BENEFIT_TEMPLATE_SUCCEED:
            return {
                ...state,
                benefitTemplate: action.payload,
                benefitTemplates: [...state.benefitTemplates, action.payload],
            };
        case actions.CLEAR_BENEFIT_TEMPLATE:
            return {
                ...state,
                benefitTemplate: null,
            };
        case actions.CHANGE_BENEFIT_STATUS_REQUESTED:
            return {
                ...state,
                isUpdating: true,
            };
        case actions.CHANGE_BENEFIT_STATUS_SUCCEED: {
            return {
                ...state,
                benefits: state.benefits.map((benefit) =>
                    benefit.id === action.payload!.id ? action.payload : benefit,
                ),
                isUpdating: false,
            };
        }
        case actions.CHANGE_BENEFIT_STATUS_FAILED:
            return {
                ...state,
                isUpdating: false,
            };
        case actions.SELECT_BENEFIT_CONTRACT:
            return {
                ...state,
                selectedContractId: action.payload,
            };
        case actions.EDIT_BENEFIT_SUCCEED:
            const filterBenefit = state.benefits.filter(({ id }) => id !== action.payload!.id);

            return {
                ...state,
                benefits: [...filterBenefit, action.payload],
            };

        case 'EDIT_BENEFIT_PRIORITY_SUCCEED': {
            const filterPriorities = state.benefit.priorities.filter(
                ({ id }) => id !== action.payload.id,
            );
            const { id, priorityName, priority, value } = action.payload;
            const updatedPriorities = orderBy(
                [
                    ...filterPriorities,
                    { id, priority: { id: priority, name: priorityName }, value },
                ],
                ['id'],
                ['asc'],
            );
            return {
                ...state,
                benefit: {
                    ...state.benefit,
                    priorities: updatedPriorities,
                },
                benefits: state.benefits.map((element) => {
                    if (element.id === state.benefit.id) {
                        return {
                            ...state.benefit,
                            priorities: updatedPriorities,
                        };
                    }
                }),
            };
        }
        case 'DELETE_BENEFIT_PRIORITY_SUCCEED': {
            return {
                ...state,
                priorities: state.priorities.filter(({ id }) => id !== action.payload),
                benefit: {
                    ...state.benefit,
                    priorities: state.benefit.priorities.filter(({ id }) => id !== action.payload),
                },
                benefits: state.benefits.map((element) =>
                    element.id === state.benefit.id
                        ? {
                              ...element,
                              priorities: state.benefit.priorities.filter(
                                  ({ id }) => id !== action.payload,
                              ),
                          }
                        : element,
                ),
            };
        }
        case 'ADD_BENEFIT_PRIORITY_SUCCEED': {
            const { id, priorityName, priority, value, benefit } = action.payload.priorities;
            const newPriority = { id, priority: { id: priority, name: priorityName }, value };

            return {
                ...state,
                priorities: [...state.benefit.priorities, newPriority],
                benefit: {
                    ...state.benefit,
                    priorities: [...state.benefit.priorities, newPriority],
                },
                benefits: state.benefits.map((element) =>
                    element.id === benefit
                        ? {
                              ...element,
                              priorities: [...element.priorities, newPriority],
                          }
                        : element,
                ),
            };
        }

        case actions.GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_SUCCEED:
            return {
                ...state,
                selectedBenefitTemplate: [
                    action.payload,
                    ...state.benefitTemplates.filter(({ id }) => id !== action.payload!.id),
                ],
                isFetching: false,
            };
        case actions.CLEAR_SELECTED_BENEFIT_TEMPLATE:
            return {
                ...state,
                selectedBenefitTemplate: [],
            };
        case actions.SET_DATE_RANGE_BENEFIT_PRIORITIES_DASHBOARD:
            return {
                ...state,
                benefitPrioritiesFilterDateRange: action.payload,
            };
        case actions.SET_DATE_RANGE_BENEFIT_TEMPLATE_DASHBOARD:
            return {
                ...state,
                benefitTemplateFilterDateRange: action.payload,
            };
        case actions.OPEN_FORM:
            return {
                ...state,
                formVisible: true,
            };
        case actions.CLOSE_FORM:
            return {
                ...state,
                formVisible: false,
            };
        default:
            return state;
    }
};
