import { getEnvValue } from 'common/helpers/getEnvValue';

export const NODE_ENV = getEnvValue('NODE_ENV');
export const REACT_APP_ENV = getEnvValue('REACT_APP_ENV');
export const API_URL = getEnvValue('REACT_APP_API_URL');
export const ENV = getEnvValue('REACT_APP_ENV');
export const SENTRY_JS = getEnvValue('REACT_APP_SENTRY_JS');
export const SENTRY_ENVIRONMENT = getEnvValue('REACT_APP_SENTRY_ENVIRONMENT');

export const isDebug = () => NODE_ENV === 'development' || REACT_APP_ENV === 'development';
