import * as React from 'react';
import { Link } from 'react-router-dom';
import { PaddedSection, UploadAndCheckTableFiltersBar } from 'common/components';
import { generateColumns, getStatusLabel } from 'common/helpers/utils';
import { FilterData } from 'common/helpers/url';
import { PaginatedTable } from 'features/pagination/components/PaginatedTable';
import { PaginationView, AutocompleteField } from 'features/pagination/models/paginationModel';
import { Antd3Form, Antd3FormProps } from 'common/components/deprecated/antd3';
import { EditPaths } from 'features/edit/models/editModel';
import { EditContractPaths } from 'features/contract/models/contractModels';

import { CheckActiveBenefit, CheckPaths } from '../models/checkModel';
import { CheckEvidencesProps as CheckEvidencesContainerProps } from '../containers/CheckEvidencesContainer';
import { getCheckActiveBenefitsSuccess } from '../actions/checkActions';

const checkEvidencesTableColumns = [
    {
        title: 'Benefit',
        key: 'outcome',
        render: (
            outcome: string,
            { id, referenceNumber, contract, hasPendingEvidence }: CheckActiveBenefit,
        ) => (
            <Link
                style={hasPendingEvidence ? { fontWeight: 'bold' } : { fontWeight: 'normal' }}
                to={`${CheckPaths.Evidence}${EditPaths.Contract}/${contract}/benefit/${id}`}
            >{`${outcome} | ${referenceNumber}`}</Link>
        ),
    },
    {
        title: 'Benefit Label',
        key: 'label',
        render: (label: string, { contract, id }: CheckActiveBenefit) => (
            <Link to={`${CheckPaths.Evidence}${EditPaths.Contract}/${contract}/benefit/${id}`}>
                {label}
            </Link>
        ),
    },
    {
        title: 'Quantity',
        key: 'quantity',
        render: (quantity: string) => quantity,
    },
    {
        title: 'Status',
        key: 'status',
        render: (status: string) => getStatusLabel(status),
    },
    {
        title: 'Completion Date',
        key: 'finalDeliveryDate',
        render: (finalDeliveryDate: string) => finalDeliveryDate,
    },
    {
        title: 'Project',
        key: 'contractTitle',
        render: (
            contractTitle: string,
            { contract, contractReferenceNumber }: CheckActiveBenefit,
        ) => (
            <Link to={`${EditPaths.Contract}/${contract}${EditContractPaths.ContractDetails}`}>
                {`${contractTitle} | ${contractReferenceNumber}`}
            </Link>
        ),
    },
];

type CheckEvidencesProps = Antd3FormProps & CheckEvidencesContainerProps;

class CheckEvidencesForm extends React.Component<CheckEvidencesProps> {
    private refreshTable = (filters?: FilterData) =>
        this.props.paginationRequest({
            view: PaginationView.CheckEvidence,
            pagination: {
                current: 1,
            },
            filters,
            paginationSuccessCallback: getCheckActiveBenefitsSuccess,
        });

    private saveBenefitFilters = (benefitReferenceNumber: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.CheckEvidence,
            values: {
                referenceNumber: benefitReferenceNumber,
            },
        });

    private saveContractFilters = (contractId: number | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.CheckEvidence,
            values: {
                contract: contractId,
            },
        });

    private saveExecutiveOrganisationFilter = (executive: string | undefined) =>
        this.props.saveFilters({
            paginatedView: PaginationView.CheckEvidence,
            values: { executive },
        });

    private searchByBenefits = (benefitReferenceNumber: string) =>
        this.saveBenefitFilters(benefitReferenceNumber) && this.refreshTable();

    private searchByContracts = (contractId: number) =>
        this.saveContractFilters(contractId) && this.refreshTable();

    private searchByExecutiveOrganisation = (executive: string) => {
        this.saveExecutiveOrganisationFilter(executive);
        this.refreshTable();
    };

    private onRemoveBenefitAutocomplete = () => {
        this.saveBenefitFilters(undefined);
        this.refreshTable();
    };

    private onRemoveExecutiveOrganisationAutocomplete = () => {
        this.saveExecutiveOrganisationFilter(undefined);
        this.refreshTable();
    };

    private onRemoveContractAutocomplete = () =>
        this.saveContractFilters(undefined) && this.refreshTable();

    private clearFilters = () =>
        this.props.clearFilters(PaginationView.CheckEvidence) &&
        this.refreshTable() &&
        this.props.form.setFieldsValue({
            quickFilterBenefitReferenceNumber: '',
            quickFilterContractReferenceNumber: '',
        });

    public render(): JSX.Element {
        const { form, filter, checkActiveBenefits, isTenant } = this.props;

        return (
            <PaddedSection header="Check Evidence (Active Benefits)">
                <UploadAndCheckTableFiltersBar
                    form={form}
                    searchByBenefits={this.searchByBenefits}
                    onRemoveBenefitAutocomplete={this.onRemoveBenefitAutocomplete}
                    searchByContracts={this.searchByContracts}
                    onRemoveContractAutocomplete={this.onRemoveContractAutocomplete}
                    searchByExecutiveOrganisation={this.searchByExecutiveOrganisation}
                    onRemoveExecutiveOrganisationAutocomplete={
                        this.onRemoveExecutiveOrganisationAutocomplete
                    }
                    filter={filter}
                    clearFilters={this.clearFilters}
                    benefitFilterAutocompleteField={AutocompleteField.CheckEvidence}
                    isTenant={isTenant}
                />
                <PaginatedTable
                    view={PaginationView.CheckEvidence}
                    paginationSuccessCallback={getCheckActiveBenefitsSuccess}
                    data={checkActiveBenefits}
                    columns={generateColumns(checkEvidencesTableColumns)}
                    emptyText="No projects have been assigned to you"
                />
            </PaddedSection>
        );
    }
}

export const CheckEvidences = Antd3Form.create({})(CheckEvidencesForm);
