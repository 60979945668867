import { ofType, combineEpics } from 'redux-observable';
import { switchMap, pluck, tap, ignoreElements } from 'rxjs/operators';

import { AppEpic } from 'common/epics/appEpic';
import { CreateAccountCredentials } from 'features/user/models/userModel';
import { Organisation } from 'features/organisation/models/organisationModels';

import * as actions from '../actions/authActions';
import { AuthService } from '../services/authService';
import { OrganisationService } from '../../organisation/services/organisationService';
import { Credentials } from '../models/credentialsModel';
import { UserService } from '../../user/services/userService';
import { of } from 'rxjs/observable/of';

export const logInEpicFactory = (
    authService: AuthService,
    userService: UserService,
    organisationService: OrganisationService,
): AppEpic => {
    const activateAccountEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ACTIVATE_ACCOUNT_REQUESTED),
            pluck('payload'),
            switchMap((credentials: CreateAccountCredentials) =>
                authService
                    .activateUser(credentials)
                    .then(actions.activateAccountSuccess)
                    .catch(actions.activateAccountFailure),
            ),
        );

    const activateExecutiveAdminAccountEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ACTIVATE_EXECUTIVE_ADMIN_ACCOUNT_REQUESTED),
            pluck('payload'),
            switchMap((credentials: CreateAccountCredentials) =>
                authService
                    .activateNewExecutiveAdmin(credentials)
                    .then(actions.activateExecutiveAdminAccountSuccess)
                    .catch(actions.activateExecutiveAdminAccountFailure),
            ),
        );

    const activateExistingExecutiveAdminAccountEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.VERIFY_EXISTING_EXECUTIVE_SUCCEED),
            pluck('payload'),
            switchMap((token: string) =>
                authService
                    .activateExistingExecutiveAdmin(token)
                    .then(actions.activateExistingExecutiveAdminSuccess)
                    .catch(actions.activateExistingExecutiveAdminFailure),
            ),
        );

    const logInEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.LOG_IN_REQUESTED),
            pluck('payload'),
            switchMap((credentials: Credentials) =>
                authService
                    .logIn(credentials)
                    .then(actions.logInSuccess)
                    .catch(actions.logInFailure),
            ),
        );

    const identifyEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.LOG_IN_SUCCEED),
            switchMap(() => of(actions.logInComplete())),
        );

    const logOutEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.LOG_OUT_REQUESTED),
            tap(() => localStorage.clear()),
            ignoreElements(),
        );

    const verifyNewAccountEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.VERIFY_NEW_EMPLOYEE_REQUESTED),
            pluck('payload'),
            switchMap((token: string) =>
                authService
                    .verifyNewUserToken(token)
                    .then(actions.verifyNewEmployeeSuccess)
                    .catch(actions.verifyNewEmployeeFailure),
            ),
        );

    const verifyNewExecutiveEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.VERIFY_NEW_EXECUTIVE_REQUESTED),
            pluck('payload'),
            switchMap((token: string) =>
                authService
                    .verifyNewExecutiveToken(token)
                    .then(actions.verifyNewExecutiveSuccess)
                    .catch(actions.verifyNewExecutiveFailure),
            ),
        );

    const verifyExistingExecutiveEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.VERIFY_EXISTING_EXECUTIVE_REQUESTED),
            pluck('payload'),
            switchMap((token: string) =>
                authService
                    .verifyExistingExecutiveToken(token)
                    .then(() => actions.verifyExistingExecutiveSuccess(token))
                    .catch(actions.verifyExistingExecutiveFailure),
            ),
        );

    return combineEpics(
        activateAccountEpic,
        activateExecutiveAdminAccountEpic,
        activateExistingExecutiveAdminAccountEpic,
        logInEpic,
        identifyEpic,
        logOutEpic,
        verifyNewAccountEpic,
        verifyNewExecutiveEpic,
        verifyExistingExecutiveEpic,
    );
};
