import { switchMap, pluck, map, debounceTime, mergeMap, catchError } from 'rxjs/operators';
import { ofType, combineEpics } from 'redux-observable';

import { showNotification } from 'common/helpers/notifications';
import { AppEpic } from 'common/epics/appEpic';
import {
    BenefitRequest,
    CreateBenefitTemplateRequest,
    BenefitPathParams,
    EditBenefitRequest,
    SelectBenefitTemplateRequest,
    BenefitsRolesRequest,
    AssignEmployeeRequest,
    FilterBenefitRequest,
    BenefitDataRequest,
    AddBenefitRoleRequest,
    DeleteBenefitRoleRequest,
    FilterContractBenefitsRequest,
    FilteredContractBenefits,
    ChangeBenefitStatusRequest,
    ToggleMultipleEmployeesRoleRequest,
    AssignActions,
    EditBenefitPriorityRequest,
    AddBenefitPriorityRequest,
    EditBenefitTemplateFilesRequest,
    ArchiveBenefitTemplateRequest,
    CreateBenefitNoteRequest,
    DeleteBenefitNoteRequest,
    EditBenefitNoteRequest,
    DeleteBenefitNoteFileRequest,
    AddBenefitNoteFileRequest,
    GetBenefitNoteRequest,
    ToggleMultipleInvitePendingExecutiveUsersRoleRequest,
    EditBenefitTemplateRequest,
    CloneBenefitTemplateRequest,
    MigrateBenefitsRequest,
} from 'features/benefit/models/benefitModels';
import { redirectToUrl } from 'common/actions/navigationAction';
import { ArchiveFilterRequest, EditFilterRequest } from 'features/contract/models/contractModels';

import * as actions from '../actions';
import { BenefitService } from '../services/benefitService';
import { EditPaths } from 'features/edit/models/editModel';
// eslint-disable-next-line no-restricted-imports
import { from, of } from 'rxjs';

export const benefitEpicFactory = (benefitService: BenefitService): AppEpic => {
    const assignEmployeesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ASSIGN_EMPLOYEES_REQUESTED),
            pluck('payload'),
            switchMap((employeeData: AssignEmployeeRequest[]) =>
                benefitService
                    .assignEmployees(employeeData)
                    .then(actions.assignEmployeesSuccess)
                    .catch(actions.assignEmployeesFailure),
            ),
        );

    const unassignEmployeesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.UNASSIGN_EMPLOYEES_REQUESTED),
            pluck('payload'),
            switchMap((data: AssignEmployeeRequest[]) =>
                benefitService
                    .unassignEmployees(data)
                    .then(actions.unassignEmployeesSuccess)
                    .catch(actions.unassignEmployeesFailure),
            ),
        );

    const archiveBenefitTemplateEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_BENEFIT_TEMPLATE_REQUESTED),
            pluck('payload'),
            switchMap(({ id, archived }: ArchiveBenefitTemplateRequest) =>
                benefitService
                    .archiveBenefitTemplate(id, archived)
                    .then(() =>
                        actions.archiveBenefitTemplateSuccess(
                            `Benefit template has been ${archived ? 'archived' : 'restored'}.`,
                        ),
                    )
                    .catch(actions.archiveBenefitTemplateFailure),
            ),
        );

    const archiveBenefitTemplateSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_BENEFIT_TEMPLATE_SUCCEED),
            map(actions.getBenefitTemplatesRequest),
        );

    const createBenefitEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_BENEFIT_REQUESTED),
            pluck('payload'),
            switchMap((benefit: BenefitRequest) =>
                benefitService
                    .createBenefit(benefit)
                    .then((data) => {
                        const newUsers = benefit.new_executive_users.map((user) => {
                            user.benefit = data.id;
                            return user;
                        });
                        const assignInvitePendingUsers =
                            benefit.invite_pending_individual_users.map((user) => {
                                user.contract = data.contract;
                                user.benefit = data.id;
                                return user;
                            });
                        return actions.createBenefitSuccess(newUsers, assignInvitePendingUsers);
                    })
                    .catch(actions.createBenefitFailure),
            ),
        );

    const createBenefitTemplateEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_BENEFIT_TEMPLATE_REQUESTED),
            pluck('payload'),
            switchMap((benefitTemplate: CreateBenefitTemplateRequest) =>
                Promise.resolve(benefitService.createBenefitTemplate(benefitTemplate))
                    .then((uploadedBenefitTemplate) =>
                        actions.createBenefitTemplateSuccess(uploadedBenefitTemplate),
                    )
                    .catch(actions.createBenefitTemplateFailure),
            ),
        );

    const filterBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.FILTER_BENEFITS_REQUESTED),
            pluck('payload'),
            switchMap((benefit: FilterBenefitRequest) =>
                benefitService
                    .filterBenefits(benefit)
                    .then(actions.filterBenefitsSuccess)
                    .catch(actions.filterBenefitsFailure),
            ),
        );

    const filterContractBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.FILTER_CONTRACT_BENEFITS_REQUESTED),
            pluck('payload'),
            switchMap(({ contractId, ...benefit }: FilterContractBenefitsRequest) =>
                benefitService
                    .filterContractBenefits({ contractId, ...benefit })
                    .then((filtered: FilteredContractBenefits) =>
                        actions.filterContractBenefitsSuccess({
                            ...filtered,
                            filteredContractId: contractId,
                        }),
                    )
                    .catch(actions.filterContractBenefitsFailure),
            ),
        );

    const filterBenefitsSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.FILTER_BENEFITS_SUCCEED, actions.FILTER_CONTRACT_BENEFITS_SUCCEED),
            pluck('payload'),
            map(({ path }) => redirectToUrl(path)),
        );

    const deleteBenefitEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_REQUESTED),
            pluck('payload'),
            switchMap((data: BenefitPathParams) =>
                benefitService
                    .deleteBenefit(data)
                    .then(actions.deleteBenefitSuccess)
                    .catch(actions.deleteBenefitFailure),
            ),
        );

    const editBenefitTemplateEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_BENEFIT_TEMPLATE_REQUESTED),
            pluck('payload'),
            switchMap((benefitTemplate: EditBenefitTemplateRequest) =>
                Promise.resolve(benefitService.editBenefitTemplate(benefitTemplate))
                    .then((updatedBenefitTemplate) =>
                        actions.editBenefitTemplateSuccess(updatedBenefitTemplate),
                    )
                    .catch(actions.editBenefitTemplateFailure),
            ),
        );

    const migrateBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.MIGRATE_BENEFITS_REQUESTED),
            pluck('payload'),
            mergeMap((payload: MigrateBenefitsRequest) =>
                benefitService
                    .migrateBenefitsBetweenTemplates(payload)
                    .then(actions.migrateBenefitsSuccess)
                    .catch(actions.migrateBenefitsFailure),
            ),
        );

    const cloneBenefitTemplateEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CLONE_BENEFIT_TEMPLATE_REQUESTED),
            pluck('payload'),
            mergeMap(({ id, form, files }: CloneBenefitTemplateRequest) =>
                Promise.resolve(
                    benefitService.createBenefitTemplate({
                        ...form,
                        outcome: `${form.outcome} (Clone of #${id})`,
                    }),
                )
                    .then((clonedBenefitTemplate) =>
                        actions.cloneBenefitTemplateSuccess({
                            id: clonedBenefitTemplate.id,
                            ...files,
                        }),
                    )
                    .catch(actions.cloneBenefitTemplateFailure),
            ),
        );

    const editClonedBenefitTemplateFilesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CLONE_BENEFIT_TEMPLATE_SUCCEED),
            pluck('payload'),
            mergeMap((data: EditBenefitTemplateFilesRequest) =>
                benefitService
                    .editBenefitTemplateFiles(data)
                    .then(() => redirectToUrl(`${EditPaths.BenefitTemplates}/${data.id}`))
                    .catch(actions.editBenefitTemplateFilesFailure),
            ),
        );

    const editBenefitTemplateFilesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_BENEFIT_TEMPLATE_FILES_REQUESTED),
            pluck('payload'),
            switchMap((payload: EditBenefitTemplateFilesRequest) =>
                benefitService
                    .editBenefitTemplateFiles(payload)
                    .then(() => actions.editBenefitTemplateFilesSuccess(payload.id))
                    .catch(actions.editBenefitTemplateFilesFailure),
            ),
        );

    const editBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_BENEFIT_REQUESTED),
            pluck('payload'),
            switchMap((benefit: EditBenefitRequest) =>
                benefitService
                    .editBenefit(benefit)
                    .then(actions.editBenefitSuccess)
                    .catch(actions.editBenefitFailure),
            ),
        );

    const editBenefitPriorityEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_BENEFIT_PRIORITY_REQUESTED),
            debounceTime(1000),
            pluck('payload'),
            switchMap((data: EditBenefitPriorityRequest) =>
                from(benefitService.editBenefitPriority(data)).pipe(
                    mergeMap((result) => [
                        actions.editBenefitPrioritySuccess(result),
                        actions.getBenefitPrioritiesRequest(data),
                    ]),
                    catchError((error) => of(actions.editBenefitPriorityFail(error))), // ✅ Handle errors properly
                ),
            ),
        );

    const deleteBenefitPriorityEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_PRIORITY_REQUESTED),
            pluck('payload'),
            switchMap((data: EditBenefitPriorityRequest) =>
                benefitService
                    .deleteBenefitPriority(data)
                    .then(actions.deleteBenefitPrioritySuccess)
                    .catch(actions.deleteBenefitPriorityFailure),
            ),
        );

    const getBenefitCategoriesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_BENEFIT_CATEGORIES_REQUESTED),
            switchMap(() =>
                benefitService
                    .getBenefitCategories()
                    .then(actions.getBenefitCategoriesSuccess)
                    .catch(actions.getBenefitCategoriesFailure),
            ),
        );

    const getMyBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_MY_BENEFITS_REQUESTED),
            switchMap(() =>
                benefitService
                    .getMyBenefits()
                    .then(actions.getMyBenefitsSuccess)
                    .catch(actions.getMyBenefitsFailure),
            ),
        );

    const getBenefitsRolesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_BENEFITS_ROLES_REQUESTED),
            pluck('payload'),
            switchMap((benefitsRolesRequest: BenefitsRolesRequest) =>
                benefitService
                    .getBenefitsRoles(benefitsRolesRequest)
                    .then(actions.getBenefitsRolesSuccess)
                    .catch(actions.getBenefitsRolesFailure),
            ),
        );

    const getBenefitsPrioritiesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_BENEFIT_PRIORITIES_REQUESTED),
            pluck('payload'),
            switchMap((data: BenefitPathParams) =>
                benefitService
                    .getBenefitPriorities(data)
                    .then(actions.getBenefitPrioritiesSuccess)
                    .catch(actions.getBenefitPrioritiesFailure),
            ),
        );

    const addBenefitPriorityEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ADD_BENEFIT_PRIORITY_REQUESTED),
            pluck('payload'),
            switchMap((data: AddBenefitPriorityRequest) =>
                benefitService
                    .addBenefitPriority(data)
                    .then(actions.addBenefitPrioritySuccess)
                    .catch(actions.addBenefitPriorityFailure),
            ),
        );

    const getContractBenefitsEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_CONTRACT_BENEFITS_REQUESTED),
            pluck('payload'),
            switchMap((contractId: number) =>
                benefitService
                    .getContractBenefits(contractId)
                    .then(actions.getContractBenefitsSuccess)
                    .catch(actions.getContractBenefitsFailure),
            ),
        );

    const getBenefitTemplatesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_BENEFIT_TEMPLATES_REQUESTED),
            switchMap(() =>
                benefitService
                    .getBenefitTemplates()
                    .then(actions.getBenefitTemplatesSuccess)
                    .catch(actions.getBenefitTemplatesFailure),
            ),
        );

    const getBenefitTemplateDataEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_BENEFIT_TEMPLATE_DATA_REQUESTED),
            switchMap(() =>
                Promise.all([
                    benefitService.getBenefitCategories(),
                    benefitService.getBenefitTemplates(),
                ])
                    .then(([benefitCategories, benefitTemplates]) =>
                        actions.getBenefitTemplateDataSuccess(benefitCategories, benefitTemplates),
                    )
                    .catch(actions.getBenefitTemplateDataFailure),
            ),
        );

    const selectBenefitTemplateEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.SELECT_BENEFIT_TEMPLATE_REQUESTED),
            pluck('payload'),
            switchMap((benefitTemplate: SelectBenefitTemplateRequest) =>
                benefitService
                    .selectBenefitTemplate(benefitTemplate)
                    .then((data) => {
                        const newUsers = benefitTemplate.new_executive_users.map((user) => {
                            user.benefit = data.id;
                            return user;
                        });
                        const assignInvitePendingUsers =
                            benefitTemplate.invite_pending_individual_users.map((user) => {
                                user.contract = data.contract;
                                user.benefit = data.id;
                                return user;
                            });
                        return actions.selectBenefitTemplateSuccess(
                            newUsers,
                            assignInvitePendingUsers,
                        );
                    })
                    .catch(actions.selectBenefitTemplateFailure),
            ),
        );

    const getContractBenefitDataEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_CONTRACT_BENEFIT_DATA_REQUESTED),
            pluck('payload'),
            switchMap((data: BenefitDataRequest) =>
                benefitService
                    .getContractBenefit(data)
                    .then(actions.getContractBenefitDataSuccess)
                    .catch((error) => {
                        showNotification({ text: error.message });
                        return actions.getContractBenefitDataFailure(error);
                    }),
            ),
        );

    const addBenefitRoleEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ADD_BENEFIT_ROLE_REQUESTED),
            pluck('payload'),
            switchMap((data: AddBenefitRoleRequest) =>
                benefitService
                    .addBenefitRole(data)
                    .then(actions.addBenefitRoleSuccess)
                    .catch(actions.addBenefitRoleFail),
            ),
        );

    const deleteBenefitRoleEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_ROLE_REQUESTED),
            pluck('payload'),
            switchMap((data: DeleteBenefitRoleRequest) =>
                benefitService
                    .deleteBenefitRole(data)
                    .then((_) => actions.deleteBenefitRoleSuccess(data))
                    .catch(actions.deleteBenefitRoleFail),
            ),
        );

    const archiveBenefitCategoryEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_BENEFIT_CATEGORY_REQUESTED),
            pluck('payload'),
            switchMap((filterData: ArchiveFilterRequest) =>
                benefitService
                    .archiveCategory(filterData)
                    .then(actions.archiveBenefitCategorySuccess)
                    .catch(actions.archiveBenefitCategoryFailure),
            ),
        );

    const archiveBenefitCategorySuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ARCHIVE_BENEFIT_CATEGORY_SUCCEED),
            map(actions.getBenefitCategoriesRequest),
        );

    const getBenefitTemplateEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_BENEFIT_TEMPLATE_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                benefitService
                    .getBenefitTemplate(id)
                    .then(actions.getBenefitTemplateSuccess)
                    .catch(actions.getBenefitTemplateFailure),
            ),
        );

    const getBenefitTemplateWhenCreatingTemplatedBenefitEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_BENEFIT_TEMPLATE_WHEN_CREATING_TEMPLATED_BENEFIT_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                benefitService
                    .getBenefitTemplate(id)
                    .then(actions.getBenefitTemplateWhenCreatingTemplatedBenefitSuccess)
                    .catch(actions.getBenefitTemplateWhenCreatingTemplatedBenefitFailure),
            ),
        );

    const changeBenefitStatusEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CHANGE_BENEFIT_STATUS_REQUESTED),
            pluck('payload'),
            switchMap((request: ChangeBenefitStatusRequest) =>
                benefitService
                    .changeBenefitStatus(request)
                    .then(actions.changeBenefitStatusSuccess)
                    .catch(actions.changeBenefitStatusFailure),
            ),
        );

    const toggleMultipleEmployeesRoleEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.TOGGLE_MULTIPLE_EMPLOYEES_ROLE_REQUESTED),
            pluck('payload'),
            switchMap((request: ToggleMultipleEmployeesRoleRequest) =>
                benefitService
                    .toggleMultipleEmployeesRole(request)
                    .then(() => {
                        const actionMessage =
                            request.action === AssignActions.Assign
                                ? 'Benefit roles have been assigned'
                                : 'Benefit roles have been revoked';

                        return actions.toggleMultipleEmployeesRoleSuccess(actionMessage);
                    })
                    .catch(actions.toggleMultipleEmployeesRoleFailure),
            ),
        );

    const toggleMultipleInvitePendingExecutiveUsersRoleEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.TOGGLE_MULTIPLE_INVITE_PENDING_EXECUTIVE_USERS_ROLE_REQUESTED),
            pluck('payload'),
            switchMap((request: ToggleMultipleInvitePendingExecutiveUsersRoleRequest) =>
                benefitService
                    .toggleMultipleInvitePendingExecutiveUsersRole(request)
                    .then(() => {
                        const actionMessage =
                            request.action === AssignActions.Assign
                                ? 'Benefit roles for invite pending users have been assigned'
                                : 'Benefit roles for invite pending users have been revoked';

                        return actions.toggleMultipleInvitePendingExecutiveUsersRoleSuccess(
                            actionMessage,
                        );
                    })
                    .catch(actions.toggleMultipleInvitePendingExecutiveUsersRoleFailure),
            ),
        );

    const deleteBenefitTemplateEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_TEMPLATE_REQUESTED),
            pluck('payload'),
            switchMap((id: number) =>
                benefitService
                    .deleteBenefitTemplate(id)
                    .then(() => actions.deleteBenefitTemplateSuccess('Employee has been deleted.'))
                    .catch(actions.deleteBenefitTemplateFailure),
            ),
        );

    const deleteBenefitTemplateSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_TEMPLATE_SUCCEED),
            map(actions.getBenefitTemplatesRequest),
        );

    const deleteBenefitCategoryFilterEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_CATEGORY_FILTER_REQUESTED),
            pluck('payload'),
            switchMap((filterData: EditFilterRequest) =>
                benefitService
                    .deleteFilter(filterData)
                    .then(actions.deleteBenefitCategoryFilterSuccess)
                    .catch(actions.deleteBenefitCategoryFilterFailure),
            ),
        );

    const deleteBenefitCategoryFilterSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_CATEGORY_FILTER_SUCCEED),
            map(actions.getBenefitCategoriesRequest),
        );

    const getBenefitNotesEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.GET_BENEFIT_NOTES_REQUESTED),
            pluck('payload'),
            switchMap((data: GetBenefitNoteRequest) =>
                benefitService
                    .getBenefitNotes(data)
                    .then(actions.getBenefitNotesSuccess)
                    .catch(actions.getBenefitNotesFailure),
            ),
        );

    const createBenefitNoteEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_BENEFIT_NOTE_REQUESTED),
            pluck('payload'),
            switchMap((benefitNote: CreateBenefitNoteRequest) =>
                benefitService
                    .createBenefitNote(benefitNote)
                    .then(() =>
                        actions.createBenefitNoteSuccess({
                            contract: benefitNote.contract,
                            benefit: benefitNote.benefit,
                        }),
                    )
                    .catch(actions.createBenefitNoteFailure),
            ),
        );

    const createBenefitNoteSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.CREATE_BENEFIT_NOTE_SUCCEED),
            pluck('payload'),
            map(({ contract, benefit }: { contract: number; benefit: number }) =>
                actions.getBenefitNotesRequest({
                    contract,
                    benefit,
                }),
            ),
        );

    const deleteBenefitNoteEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_NOTE_REQUESTED),
            pluck('payload'),
            switchMap((data: DeleteBenefitNoteRequest) =>
                benefitService
                    .deleteBenefitNote(data)
                    .then(() =>
                        actions.deleteBenefitNoteSuccess({
                            contract: data.contract,
                            benefit: data.benefit,
                        }),
                    )
                    .catch(actions.deleteBenefitNoteFailure),
            ),
        );

    const deleteBenefitNoteSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_NOTE_SUCCEED),
            pluck('payload'),
            map(({ contract, benefit }: { contract: number; benefit: number }) =>
                actions.getBenefitNotesRequest({
                    contract,
                    benefit,
                }),
            ),
        );

    const editBenefitNoteEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_BENEFIT_NOTE_REQUESTED),
            pluck('payload'),
            switchMap((data: EditBenefitNoteRequest) =>
                benefitService
                    .editBenefitNote(data)
                    .then(() =>
                        actions.editBenefitNoteSuccess({
                            contract: data.contract,
                            benefit: data.benefit,
                        }),
                    )
                    .catch(actions.editBenefitNoteFailure),
            ),
        );

    const editBenefitNoteSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.EDIT_BENEFIT_NOTE_SUCCEED),
            pluck('payload'),
            map(({ contract, benefit }: { contract: number; benefit: number }) =>
                actions.getBenefitNotesRequest({
                    contract,
                    benefit,
                }),
            ),
        );

    const deleteBenefitNoteFileEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_NOTE_FILE_REQUESTED),
            pluck('payload'),
            switchMap((data: DeleteBenefitNoteFileRequest) =>
                benefitService
                    .deleteBenefitNoteFile(data)
                    .then(() =>
                        actions.deleteBenefitNoteFileSuccess({
                            contract: data.contract,
                            benefit: data.benefit,
                        }),
                    )
                    .catch(actions.deleteBenefitNoteFileFailure),
            ),
        );

    const deleteBenefitNoteFileSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.DELETE_BENEFIT_NOTE_FILE_SUCCEED),
            pluck('payload'),
            map(({ contract, benefit }: { contract: number; benefit: number }) =>
                actions.getBenefitNotesRequest({
                    contract,
                    benefit,
                }),
            ),
        );

    const addBenefitNoteFileEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ADD_BENEFIT_NOTE_FILE_REQUESTED),
            pluck('payload'),
            switchMap((data: AddBenefitNoteFileRequest) =>
                benefitService
                    .addBenefitNoteFile(data)
                    .then(() =>
                        actions.addBenefitNoteFileSuccess({
                            contract: data.contract,
                            benefit: data.benefit,
                        }),
                    )
                    .catch(actions.addBenefitNoteFileFailure),
            ),
        );

    const addBenefitNoteFileSuccessEpic: AppEpic = (action$) =>
        action$.pipe(
            ofType(actions.ADD_BENEFIT_NOTE_FILE_SUCCEED),
            pluck('payload'),
            map(({ contract, benefit }: { contract: number; benefit: number }) =>
                actions.getBenefitNotesRequest({
                    contract,
                    benefit,
                }),
            ),
        );

    return combineEpics(
        assignEmployeesEpic,
        unassignEmployeesEpic,
        archiveBenefitTemplateEpic,
        archiveBenefitTemplateSuccessEpic,
        createBenefitEpic,
        createBenefitTemplateEpic,
        cloneBenefitTemplateEpic,
        editBenefitTemplateEpic,
        editClonedBenefitTemplateFilesEpic,
        deleteBenefitEpic,
        migrateBenefitsEpic,
        filterBenefitsEpic,
        filterContractBenefitsEpic,
        filterBenefitsSuccessEpic,
        editBenefitsEpic,
        getMyBenefitsEpic,
        getBenefitCategoriesEpic,
        getBenefitsRolesEpic,
        getContractBenefitsEpic,
        getBenefitTemplatesEpic,
        getBenefitTemplateDataEpic,
        selectBenefitTemplateEpic,
        getContractBenefitDataEpic,
        addBenefitRoleEpic,
        deleteBenefitRoleEpic,
        archiveBenefitCategoryEpic,
        archiveBenefitCategorySuccessEpic,
        getBenefitTemplateEpic,
        changeBenefitStatusEpic,
        toggleMultipleEmployeesRoleEpic,
        getBenefitsPrioritiesEpic,
        editBenefitPriorityEpic,
        addBenefitPriorityEpic,
        deleteBenefitPriorityEpic,
        editBenefitTemplateFilesEpic,
        getBenefitTemplateWhenCreatingTemplatedBenefitEpic,
        deleteBenefitTemplateEpic,
        deleteBenefitTemplateSuccessEpic,
        deleteBenefitCategoryFilterEpic,
        deleteBenefitCategoryFilterSuccessEpic,
        getBenefitNotesEpic,
        createBenefitNoteEpic,
        createBenefitNoteSuccessEpic,
        deleteBenefitNoteEpic,
        deleteBenefitNoteSuccessEpic,
        editBenefitNoteEpic,
        editBenefitNoteSuccessEpic,
        deleteBenefitNoteFileEpic,
        deleteBenefitNoteFileSuccessEpic,
        addBenefitNoteFileEpic,
        addBenefitNoteFileSuccessEpic,
        toggleMultipleInvitePendingExecutiveUsersRoleEpic,
    );
};
